import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Key Facts `}</strong></p>
        <ul>
          <li parentName="ul">{`volatility-targeting strategy`}</li>
          <li parentName="ul">{`leveraged w/ up to 130% exposure`}</li>
          <li parentName="ul">{`invests in ETFs tracking stocks and treasury bonds`}</li>
          <li parentName="ul">{`rebalances daily`}</li>
        </ul>
        <p><strong parentName="p">{` Similar Portfolios `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This portfolio is `}<strong parentName="p">{`outdated`}</strong>{` and requires improvements to better cope with environments of rising bond yields. In the meantime, we suggest using `}<a parentName="p" {...{
            "href": "/portfolios/tt-round-robin/"
          }}>{`Round Robin`}</a>{` as a replacement.`}</p>
      </Border><br />
      <p><em parentName="p">{`Pick Me Up`}</em>{` is a proprietary premium strategy by `}<em parentName="p">{`TuringTrader.com`}</em>{`, introduced in February 2020.`}</p>
      <p><em parentName="p">{`Pick Me Up`}</em>{` aims to beat the S&P 500 while at the same time offering lower volatility. To achieve this goal, `}<em parentName="p">{`Pick Me Up`}</em>{` combines volatility-targeting with moderate leverage. The strategy invests in an S&P 500 Index ETF, as well as in a 3x leveraged ETF tracking the same index, reaching up to 130% total exposure to the stock market. Volatility-targeting is used to manage portfolio volatility and tame volatility-decay of the leveraged ETF. As a risk-off asset, `}<em parentName="p">{`Pick Me Up`}</em>{` uses U.S. treasury bonds.`}</p>
      <p><em parentName="p">{`Pick Me Up`}</em>{` has moderate maintenance requirements. While the strategy calls for daily rebalancing, its short menu of ETFs makes this task straightforward.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Pick Me Up: A leveraged volatility-targeting strategy",
                "title": "TuringTrader.com, Pick Me Up: A leveraged volatility-targeting strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/volatility-targeting/"
            }}>{`Volatility Targeting`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/leveraged-etf-portfolios/"
            }}>{`Leveraged ETF Portfolios`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Pick Me Up`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`scale exposure to the S&P 500 inversely to historical volatility`}</li>
        <li parentName="ul">{`reduce this exposure if`}
          <ul parentName="li">
            <li parentName="ul">{`VIX term structure enters backwardation`}</li>
            <li parentName="ul">{`12-months rolling maximum drawdowns exceed unhealthy levels`}</li>
            <li parentName="ul">{`the leveraged ETF fails to properly track its underlying`}</li>
            <li parentName="ul">{`the economy enters a recession, as detected through the unemployment rate`}</li>
          </ul>
        </li>
        <li parentName="ul">{`invest the remaining capital in U.S. treasury bonds`}</li>
      </ul>
      <p><em parentName="p">{`Pick Me Up`}</em>{` reflects the idea that in the long term there is no better growth opportunity than the U.S. stock market. The biggest risk to this approach stems from periods of high volatility, or economic downturns. We encourage investors keen on learning more about the rationale behind the strategy to read our articles regarding volatility targeting and leveraged ETFs.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`The strategy is well diversified in terms of individual titles, as it only invests in broad indices. However, in bullish periods of low volatility, the strategy may have up to 130% exposure to the stock market, leading to amplified tail risk in case of rapidly spiking volatility.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p><em parentName="p">{`Pick Me Up`}</em>{` beats the S&P 500 in most years, and yet has shown docile behavior throughout the last economic cycle. The portfolio managed the 2008 recession quite well, even though partially missing out on the 2009 rebound. The strategy’s average drawdowns and maximum drawdowns are significantly lower than buy & hold, and recovery from drawdowns is much faster.`}</p>
      <p>{`The Monte-Carlo simulation confirms these observations. Even though we have high confidence in `}<em parentName="p">{`Pick Me Up`}</em>{`’s maximum drawdown to be lower than that of the index, it might, however, not be as low as the backtest suggests. More reasonably, investors should anticipate a maximum drawdown of around 35%.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy scales its stock market exposure, it triggers taxable events. However, `}<em parentName="p">{`Pick Me Up`}</em>{` holds at least 60% of its positions for several years. Therefore, `}<em parentName="p">{`Pick Me Up`}</em>{` is a great candidate for taxable accounts, with a tax burden similar to that of a passive 60/40 portfolio.`}</p>
      <p><em parentName="p">{`Pick Me Up`}</em>{` makes use of a 3x leveraged index ETF, which is considered a high-risk instrument. Most brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts.`}</p>
      <p><em parentName="p">{`Pick Me Up`}</em>{` invests in no more than two ETFs at a time. Therefore, it should function as intended with as little as $3,000 of capital.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, February 2020`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      